import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import toast, { Toaster } from "react-hot-toast";
import { RequireAuth, useAuthUser, useIsAuthenticated } from "react-auth-kit";



const App = () => {
  const isAuthenticated = useIsAuthenticated();
  // const [selectedCenter, setSelectedCentre] = useState(null);
  const [newNotif, setnewNotif] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const auth = useAuthUser();


  useEffect(() => {
    if (notification?.title) {
      setnewNotif(true);
    }
  }, [notification]);

  // inside the jsx being returned:


  return (
    <>
      <Toaster />
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<RequireAuth loginPath={"/auth/sign-in"}><AdminLayout /></RequireAuth>} />
        <Route path="rtl/*" element={<RequireAuth loginPath={"/auth/sign-in"}><RtlLayout /></RequireAuth>} />
        <Route path="/" element={<Navigate to={!isAuthenticated() ? `/auth/sign-in` : 'admin/stats'} replace />} />
      </Routes>
    </>
  );
};

export default App;
