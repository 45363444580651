

import InputField from "components/fields/InputField";
import useDataFetching from "hooks/useDataFetching";
import React, { useEffect, useState } from "react";

import { config } from "variables/config";

import { AppBar, Button, capitalize, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Skeleton, Slide, Toolbar, Typography } from "@mui/material";
import MButton from "components/Mbutton";

import { CloseModalButton } from "components/Utils";
// import { Transition } from "views/admin/tables/AddEnterprise";
import { formatDate } from "utils";
import { Link } from "react-router-dom";
import AddEnterprise from "views/admin/tables/AddEnterprise";
import AddContract from "views/admin/employes/AddContract";
import AddEmployer from "views/admin/employes/AddEmployer";
import { Bs0CircleFill, BsArrowLeft, BsArrowLeftCircle, BsArrowRight, BsCheckCircleFill, BsClockFill, BsExclamationCircleFill, BsXCircleFill } from "react-icons/bs";
import usePostAxiosData from "hooks/usePostAxiosData";
import toast from "react-hot-toast";
import { useAuthUser } from "react-auth-kit";
import Error from "components/message/Error";
import PDFViewer from "components/PDFViewer";
import MuiCollapse from "components/MuiCollapse";
import { formatDateFrom } from "utils";
import { formatNumber } from "utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const ConfirmRejectModal = ({ open, onClose, onConfirm, title, content, withMotif }) => {
  const authUser = useAuthUser()
  const [motif, setMotif] = useState('');
  const [error, seterror] = useState('');
  // console.log("🚀 ~ ConfirmRejectModal ~ error:", error)
  const handleConfirm = () => {

    if (withMotif && !motif) {
      seterror("Merci de rensigner le motif! ")
      return
    }
    seterror('')
    onConfirm({
      motif: motif || ' ',
      adminId: authUser()?.user?.id
    });
    onClose();
  }
  const handleCloseIside = () => {
    seterror('')
    onClose()
  }

  useEffect(() => {
    if (!motif) { seterror("Merci de rensigner le motif! ") }
    else if (motif?.length <= 10) {
      seterror("Entrez au moins 10 caracteres !")
    }
    else {
      seterror('')
    }
  }, [motif]);
  return (
    <Dialog open={open} onClose={onClose}
      fullWidth={true}

      maxWidth={"md"}

      aria-labelledby="customized-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle>{title || "Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="h5" >
          {content || "Êtes-vous sûr de bien vouloir rejeter ces modifications ?"}
        </DialogContentText>
        {withMotif && <><hr className="my-4" />
          <InputField
            label={"Entrez le motif en cas de reject"}
            disabled={true}
            type="text"
            multiline={true}
            value={motif}
            onChange={(text) => { setMotif(text.target.value) }}
            error={error}
          />
        </>
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseIside}
          color="error"
          variant="outlined"
        >
          Annuler
        </Button>
        <Button
          onClick={handleConfirm}
          color="success"
          variant="contained"
          autoFocus
        >
          Continuer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RequestDetails = ({ selectedRequest, open, handleClose, get, requestEntityType }) => {


  let url = requestEntityType == 'ENTERPRISE' ? 'enterprises' : requestEntityType == 'EMPLOYEE' ? 'employees' : requestEntityType == 'CONTRACT' ? 'contracts' : null;
  const [loading, error, request, getRequest] = useDataFetching(selectedRequest?.id && url ? `${config.app.api_url}/user-requests/${url}/${selectedRequest?.id}` : null);

  const [loadingCompleted, postCompleted, errorMassageCompleted, successMessageComplted, resultCmp] = usePostAxiosData(url ? `user-requests/${url}/${selectedRequest?.id}/completed` : null, "PUT");
  const [loadingReject, postreject, errorMassageReject, successMessageReject, resultReject] = usePostAxiosData(url ? `user-requests/${url}/${selectedRequest?.id}/rejected` : null, "PUT");

  const [openEnterDetail, setOpenEnterDetail] = useState(false);
  const [openEmployerDetails, setOpenEmployerDetails] = useState(false);
  const [openContractDetaill, setOpenContractDetaill] = useState(false);

  const handlePostCompleted = () => {
    if (files?.find((f) => f.openned == false)) {
      alert("Merci de colsulter les fichiers joints.")
      return
    }
    // window.confirm("Etes vous sur de bien vouloir accepter ces modificatons ? ") && postCompleted({
    //   motif: "Done",
    //   adminId: authUser()?.user?.id
    // })
    setOpenAccept(true)
  }

  const handlePostreject = () => {
    if (files?.find((f) => f.openned == false)) {
      alert("Merci de colsulter les fichiers joints.")
      return
    }
    setOpenReject(true)
    // window.confirm('Etes vous sur de bien vouloir Rejecter ces modifications ?') && postreject({
    //   motif: "to provide",
    //   adminId: authUser()?.user?.id
    // })
  }
  const handleCloseInside = () => {
    // navigation(-1)
    handleClose()
  }
  const [files, setFiles] = useState([]);
  // console.log("🚀 ~ RequestDetails ~ files:", files)

  useEffect(() => {
    request?.data?.attachedFilesUrls && setFiles(request?.data?.attachedFilesUrls?.map((f) => { return { openned: false, name: f } }))
  }, [request]);
  useEffect(() => {
    if (successMessageComplted?.status === 200 || successMessageComplted?.status === 201) {
      getRequest()
      get()
      toast.success(
        `${successMessageComplted?.message != null
          ? successMessageComplted?.message
          : `Requete traiter avec success.`
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );
      // handleSignin(result);
    }
  }, [successMessageComplted]);
  useEffect(() => {
    if (successMessageReject?.status === 200 || successMessageReject?.status === 201) {
      getRequest()
      get()

      toast.success(
        `${successMessageReject?.message != null
          ? successMessageReject?.message
          : `Requete traiter avec success.`
        } `,
        {
          duration: 4000,
          // icon: "👏",
          className: "bg-success text-white",
        }
      );
      // handleSignin(result);
    }
  }, [successMessageReject]);
  const Section = ({ title, handleClickMore, children, active }) => {

    return <div className={`flex  flex-col shadow border p-2 rounded-lg ${(active && request?.data?.status == 'COMPLETED') ? "border-green-500 bg-green-50" : active ? "border-red-500 bg-red-50" : "bg-white "}`}>

      {active && request?.data?.status == 'REJECTED' && <Typography variant="body1"
        fontStyle={"italic"}
        fontWeight={700} className=" flex items-center justify-center gap-2 bg-red-400 border border-red-100 rounded-lg p-2  shadow-lg" align="center">
        <BsXCircleFill color="black" size={25} />
        Une requete de modification a ete refusee pour cette section le <strong>{formatDate(request?.data?.completedAt)}, il y'a {formatDateFrom(request?.data?.completedAt)} </strong>
      </Typography>}
      {active && request?.data?.status == 'COMPLETED' && <Typography variant="body1"
        fontStyle={"italic"}
        fontWeight={700} className=" flex items-center justify-center gap-2 bg-green-100 border border-green-100 rounded-lg p-2  shadow-lg" align="center">
        <BsCheckCircleFill color="green" size={25} />
        Une requete de modification a ete effectuee avec success sur cette section le <strong>{formatDate(request?.data?.completedAt)}, il y'a {formatDateFrom(request?.data?.completedAt)}</strong>
      </Typography>}
      {active && request?.data?.status != 'REJECTED' && request?.data?.status != 'COMPLETED' && <Typography variant="body1"
        fontStyle={"italic"}
        fontWeight={700} className=" flex items-center justify-center gap-2 bg-orange-100 border border-orange-100 rounded-lg p-2  shadow-lg" align="center">
        <BsExclamationCircleFill color="black" size={25} />
        Une requete de modification a ete emise pour cette section le <strong>{formatDate(request?.data?.createdAt)}, il y'a {formatDateFrom(request?.data?.createdAt)} </strong>
      </Typography>}
      <Typography variant="h6">{title}</Typography>

      <div className="flex flex-col">
        {children}
        <div className="flex justify-end me-3 mt-2 gap-4">
          {/* <Divider className="my-3"/> */}
          {handleClickMore && <Link
            className="text-blue-500 border-border-blue-500  bg-blue-100 p-2 rounded-[60px] text-[15px] shadow italic"
            // to={detailPagePath}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleClickMore()
            }}
          >Details</Link>}
          {active && <>
            {request?.data?.status == 'PENDING' && <MButton
              // className="text-red-500 border-border-red-500  bg-red-100 p-2 rounded-[60px] text-[15px] shadow italic"
              // to={detailPagePath}
              variant={'danger'}

              isLoading={loadingReject}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handlePostreject()
              }}
            >Rejeter les modifications</MButton>}
            {request?.data?.status == 'PENDING' && <MButton
              variant={'primary'}
              // className="text-orange-500 border-border-orange-500  bg-orange-100 p-2 rounded-[60px] text-[15px] shadow italic"
              isLoading={loadingCompleted}

              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handlePostCompleted()
              }}
            >Valider les modifications</MButton>}

          </>
          }
          {handleClickMore && !active && <Link
            className="text-blue-500  bg-blue-100 p-2 rounded-[60px] text-[11px] shadow italic"
            // to={detailPagePath}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleClickMore()
            }}
          >Voir plus de details...</Link>}
        </div>
      </div>
    </div>
  }

  const Row = ({ label, change, current }) => {
    return (
      change && change != '--' && <div className="flex gap-4 items-center content-center">

        <InputField

          label={label}
          disabled={true}
          type="text"
          multiple={true}
          value={current || '--'}
        />

        {
          request?.data?.status == 'REJECTED' ? <div className="flex items-center "> <BsArrowRight size={30} color={"orange"} /><BsXCircleFill size={20} color="red" /> </div> :
            request?.data?.status == 'COMPLETED' ? <div className="flex items-center"> <BsArrowRight size={30} color={"orange"} /><BsCheckCircleFill size={20} color="green" /> </div> :
              request?.data?.status == 'PENDING' ? <div className="flex items-center"> <BsArrowRight size={30} color={"orange"} /><BsClockFill size={20} color="orange" /> </div>
                : <BsArrowRight size={60} color={"orange"} />}
        <InputField
          color={request?.data?.status == 'COMPLETED' ? 'green' : 'red'}
          label={label}
          disabled={true}
          type="text"
          multiple={true}
          value={change}
        />
      </div>
    )
  }
  const [openAccept, setOpenAccept] = useState(false);
  const [openReject, setOpenReject] = useState(false);


  return (
    <>
      <ConfirmRejectModal title={"Confirmation d'approbation"} content={"Êtes-vous sûr de bien vouloir approuver ces modifications ?"} open={openAccept} onClose={() => setOpenAccept(false)} onConfirm={postCompleted} />
      <ConfirmRejectModal title={"Confirmation de rejet"} withMotif={true} content={"Êtes-vous sûr de bien vouloir rejeter ces modifications ?"} open={openReject} onClose={() => setOpenReject(false)} onConfirm={postreject} />

      {request?.data?.user?.myEnterprise && <AddEnterprise selectedEnterpriseP={request?.data?.user?.myEnterprise} open={openEnterDetail} handleCloseOnly={() => setOpenEnterDetail(false)} get={getRequest} />}
      {request?.data?.employer && <AddEmployer selectedEmployerP={request?.data?.employer} open={openEmployerDetails} handleCloseOnly={() => setOpenEmployerDetails(false)} get={getRequest} />}
      {request?.data?.contract && <AddContract selectedContract={request?.data?.contract} selectedEmployer={request?.data?.employer || request?.data?.contract?.employer} get={getRequest} open={openContractDetaill} handleCloseOnly={() => setOpenContractDetaill(false)} />}
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleCloseInside}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative' }}>
          <Toolbar className="flex justify-between bg-gray-50">
            <Typography color={'black'}>
              Informations du compte enterprise
            </Typography>
            {request?.data?.status == 'REJECTED' && <Typography align="'center" variant="h6" className=" flex items-center gap-2 bg-red-50 text-red-500 rounded-[60px] p-2 px-4">
              <BsXCircleFill />
              {request?.data?.status}
            </Typography>}
            {request?.data?.status == 'COMPLETED' && <Typography align="'center" variant="h6" className="flex items-center gap-2 bg-green-50 text-green-500 rounded-[60px] p-2 px-4">
              <BsCheckCircleFill />
              {request?.data?.status}
            </Typography>}
            {request?.data?.status != 'REJECTED' && request?.data?.status != 'COMPLETED' && <Typography align="'center" variant="h6" className="flex items-center gap-2 bg-orange-50 text-orange-500 rounded-[60px] p-2 px-4">
              <BsClockFill />
              {request?.data?.status || 'PENDING'}
            </Typography>}
            <CloseModalButton iconColor={"red"} handleClose={handleCloseInside} />

          </Toolbar>
        </AppBar>

        <DialogContent className="bg-gray-200" dividers>

          <div className="flex flex-col gap-4">
            {errorMassageCompleted?.message && (
              <Error
                message={
                  errorMassageCompleted?.message !== "" && errorMassageCompleted?.message
                }
              />
            )}
            {errorMassageReject?.message && (
              <Error
                message={
                  errorMassageReject?.message !== "" && errorMassageReject?.message
                }
              />
            )}
            <div className="flex flex-col gap-4" >
              {loading ?<>
                <Skeleton height={300} width={'100%'} /> 
                <Skeleton height={300} width={'100%'} /> 
                <Skeleton height={300} width={'100%'} /> 
              </> 
              :
                !request?.data ? <Typography variant="h4" color={'red'}>Pas de contenur</Typography> : <>
                  <Section
                    title={"Informations sur la requete"}
                  >

                    <div className="mt-4 grid gap-2 md:grid-cols-2">
                      <InputField
                        label="Type de donnee a affecter"
                        disabled={true}
                        type="text"
                        multiple={true}
                        value={requestEntityType}
                      />
                      <InputField
                        label="Type de requete"
                        disabled={true}
                        type="text"
                        multiple={true}
                        value={request?.data?.requestType}
                      />
                      <InputField
                        label="Motif"
                        disabled={true}
                        type="text"
                        multiple={true}
                        value={request?.data?.motif}
                      />

                      <InputField
                        label="Description"
                        disabled={true}
                        type="text"
                        multiple={true}
                        value={request?.data?.description}
                      />


                    </div>
                  </Section>

                  <Section

                    title={"Informations sur l'utilisateur emmetteur"}>

                    <div className="mt-4 grid gap-2 md:grid-cols-2">

                      <InputField
                        label="Nom"
                        disabled={true}
                        type="text"
                        multiple={true}
                        value={request?.data?.user?.firstName}
                      />
                      <InputField
                        label="Prenom"
                        disabled={true}
                        type="text"
                        multiple={true}
                        value={request?.data?.user?.lastName}
                      />

                      <InputField
                        label="Email"
                        disabled={true}
                        type="text"
                        multiple={true}
                        value={request?.data?.user?.mail}
                      />
                      <InputField
                        label="Phone"
                        disabled={true}
                        type="text"
                        multiple={true}
                        value={request?.data?.user?.phone}
                      />


                    </div>
                  </Section>

                  <Section
                    active={requestEntityType == "ENTERPRISE"}
                    title={"Informations sur l'entreprise emmetrice"}
                    handleClickMore={() => setOpenEnterDetail(true)}
                  >

                    {!request?.data?.user?.myEnterprise ? <Typography variant="body1" color={'red'} fontStyle={'italic'} >Aucune entreprise  cible</Typography> :

                      requestEntityType != "ENTERPRISE" ?
                        <div className="mt-4 grid gap-2 md:grid-cols-2">

                          <InputField
                            label="Nom"
                            disabled={true}
                            type="text"
                            multiple={true}
                            value={request?.data?.user?.myEnterprise?.socialRaison}
                          />
                          <InputField
                            label="Prenom"
                            disabled={true}
                            type="text"
                            multiple={true}
                            value={request?.data?.user?.myEnterprise?.sigle}
                          />

                          <InputField
                            label="Niu"
                            disabled={true}
                            type="text"
                            multiple={true}
                            value={request?.data?.user?.myEnterprise?.nui}
                          />
                          <InputField
                            label="Arrodissemnet"
                            disabled={true}
                            type="text"
                            multiple={true}
                            value={request?.data?.user?.myEnterprise?.arrondissement?.name}
                          />

                        </div>
                        :
                        <div className="flex flex-col gap-3">

                          <Row
                            label={"Raison sociale"}
                            current={request?.data?.user?.myEnterprise?.socialRaison}
                            change={request?.data?.socialRaison}
                          />

                          <Row
                            label={"Sigle"}
                            current={request?.data?.user?.myEnterprise?.sigle}
                            change={request?.data?.sigle}
                          />

                          <Row
                            label={"Numéro d'identification unique (NUI)"}
                            current={request?.data?.user?.myEnterprise?.nui}
                            change={request?.data?.nui}
                          />

                          <Row
                            label={"Localité"}
                            current={request?.data?.user?.myEnterprise?.locality}
                            change={request?.data?.locality}
                          />

                          <Row
                            label={"Code postal"}
                            current={request?.data?.user?.myEnterprise?.postal}
                            change={request?.data?.postal}
                          />

                          <Row
                            label={"Rue"}
                            current={request?.data?.user?.myEnterprise?.street}
                            change={request?.data?.street}
                          />

                          <Row
                            label={"Site web"}
                            current={request?.data?.user?.myEnterprise?.website}
                            change={request?.data?.website}
                          />

                          <Row
                            label={"Email"}
                            current={request?.data?.user?.myEnterprise?.email}
                            change={request?.data?.email}
                          />

                          <Row
                            label={"Téléphone"}
                            current={request?.data?.user?.myEnterprise?.phone}
                            change={request?.data?.phone}
                          />

                          <Row
                            label={"Nom du responsable RH"}
                            current={request?.data?.user?.myEnterprise?.rhname}
                            change={request?.data?.rhname}
                          />

                          <Row
                            label={"Email du responsable RH"}
                            current={request?.data?.user?.myEnterprise?.rhemail}
                            change={request?.data?.rhemail}
                          />

                          <Row
                            label={"Téléphone du responsable RH"}
                            current={request?.data?.user?.myEnterprise?.rhphone}
                            change={request?.data?.rhphone}
                          />

                          <Row
                            label={"WhatsApp du responsable RH"}
                            current={request?.data?.user?.myEnterprise?.rhphonewh}
                            change={request?.data?.rhphonewh}
                          />

                          <Row
                            label={"Longitude"}
                            current={request?.data?.user?.myEnterprise?.longitude}
                            change={request?.data?.longitude}
                          />

                          <Row
                            label={"Latitude"}
                            current={request?.data?.user?.myEnterprise?.latitude}
                            change={request?.data?.latitude}
                          />

                          <Row
                            label={"Région"}
                            current={request?.data?.user?.myEnterprise?.region?.name}
                            change={request?.data?.region?.name}
                          />

                          <Row
                            label={"Département"}
                            current={request?.data?.user?.myEnterprise?.department?.name}
                            change={request?.data?.department?.name}
                          />

                          <Row
                            label={"Arrondissement"}
                            current={request?.data?.user?.myEnterprise?.arrondissement?.name}
                            change={request?.data?.arrondissement?.name}
                          />

                          <Row
                            label={"Secteurs d'activité"}
                            current={request?.data?.user?.myEnterprise?.activitySectorsIds?.join(", ")}
                            change={request?.data?.activitySectorsIds?.join(", ")}
                          />

                        </div>
                    }



                  </Section>

                  {request?.data?.employer && <Section
                    active={requestEntityType == "EMPLOYEE"}
                    title={"Informations sur l'employe a modifier"}
                    handleClickMore={() => setOpenEmployerDetails(true)}

                  >


                    {/* //  <Typography variant="body1" color={'red'} fontStyle={'italic'} >Aucun employe cible</Typography>  */}
                    <div className="flex flex-col gap-3">

                      <Row
                        label={"Nom Complet"}
                        current={request?.data?.employer?.fullName}
                        change={request?.data?.fullName}
                      />
                      <Row
                        label={"Prenom"}
                        current={request?.data?.employer?.firstName}
                        change={request?.data?.firstName}
                      />
                      <Row
                        label={"Nom"}
                        current={request?.data?.employer?.lastName}
                        change={request?.data?.lastName}
                      />
                      <Row
                        label={"Sexe"}
                        current={request?.data?.employer?.sexe}
                        change={request?.data?.sexe}
                      />

                      <Row
                        label={"Nationality"}
                        current={request?.data?.employer?.nationality}
                        change={request?.data?.nationality}
                      />
                      <Row
                        label={"Sexe"}
                        current={request?.data?.employer?.sexe}
                        change={request?.data?.sexe}
                      />

                      <Row
                        label={"Nationality"}
                        current={request?.data?.employer?.nationality}
                        change={request?.data?.nationality}
                      />

                      <Row
                        label={"Date de naissance"}
                        current={request?.data?.employer?.birthDate}
                        change={request?.data?.birthDate}
                      />

                      <Row
                        label={"Téléphone"}
                        current={request?.data?.employer?.phone}
                        change={request?.data?.phone}
                      />

                      <Row
                        label={"Email"}
                        current={request?.data?.employer?.email}
                        change={request?.data?.email}
                      />

                      <Row
                        label={"Visa (opt date)"}
                        current={request?.data?.employer?.visaOptDate}
                        change={request?.data?.visaOptDate}
                      />

                      <Row
                        label={"Qualification"}
                        current={request?.data?.employer?.qualification}
                        change={request?.data?.qualification}
                      />

                      <Row
                        label={"Numéro de visa"}
                        current={request?.data?.employer?.visaNumber}
                        change={request?.data?.visaNumber}
                      />

                      <Row
                        label={"Date d'expiration du visa"}
                        current={request?.data?.employer?.visaExpDate}
                        change={request?.data?.visaExpDate}
                      />

                      <Row
                        label={"Type de visa"}
                        current={request?.data?.employer?.visaType}
                        change={request?.data?.visaType}
                      />

                      <Row
                        label={"Type de résident"}
                        current={request?.data?.employer?.residentType}
                        change={request?.data?.residentType}
                      />

                      <Row
                        label={"Type d'entrée"}
                        current={request?.data?.employer?.entryType}
                        change={request?.data?.entryType}
                      />

                      <Row
                        label={"Numéro du permis de résidence"}
                        current={request?.data?.employer?.residencePermitNumber}
                        change={request?.data?.residencePermitNumber}
                      />

                      <Row
                        label={"Date d'expiration du permis de résidence"}
                        current={request?.data?.employer?.residencePermitExpDate}
                        change={request?.data?.residencePermitExpDate}
                      />

                      <Row
                        label={"A un permis de résidence"}
                        current={request?.data?.employer?.hasResidentPermit}
                        change={request?.data?.hasResidentPermit}
                      />

                      <Row
                        label={"Statut de l'employé"}
                        current={request?.data?.employer?.employeStatus}
                        change={request?.data?.employeStatus}
                      />

                      <Row
                        label={"URL du fichier du passeport"}
                        current={request?.data?.employer?.passportFileUrl}
                        change={request?.data?.passportFileUrl}
                      />

                      <Row
                        label={"Numéro de passeport"}
                        current={request?.data?.employer?.passportNumber}
                        change={request?.data?.passportNumber}
                      />

                      <Row
                        label={"Date d'expiration du passeport"}
                        current={request?.data?.employer?.passportExpDate}
                        change={request?.data?.passportExpDate}
                      />

                      <Row
                        label={"Diplôme"}
                        current={request?.data?.employer?.diplome?.name}  // Assuming Diplome has a name attribute
                        change={request?.data?.diplome?.name}
                      />

                      <Row
                        label={"A un permis"}
                        current={request?.data?.employer?.hasPermit}
                        change={request?.data?.hasPermit}
                      />


                    </div>
                  </Section>
                  }

                  {request?.data?.contract && <Section
                    active={requestEntityType == "CONTRACT"}
                    title={"Informations sur le contract a modifier"}
                    handleClickMore={() => setOpenContractDetaill(true)}
                  >


                    {/* // <Typography variant="body1" color={'red'} fontStyle={'italic'} >Aucun contract cible</Typography>  */}
                    <div className="flex flex-col gap-3">

                      <Row
                        label={"Poste"}
                        current={request?.data?.contract?.occupation?.name}
                        change={request?.data?.occupation?.name}
                      />

                      <Row
                        label={"Salaire"}
                        current={formatNumber(request?.data?.contract?.salary)}
                        change={formatNumber(request?.data?.salary)}
                      />

                      <Row
                        label={"Indemnité de logement"}
                        current={formatNumber(request?.data?.contract?.indLogement)}
                        change={formatNumber(request?.data?.indLogement)}
                      />

                      <Row
                        label={"Indemnité de transport"}
                        current={formatNumber(request?.data?.contract?.indTransport)}
                        change={formatNumber(request?.data?.indTransport)}
                      />

                      <Row
                        label={"Indemnité de responsabilité"}
                        current={formatNumber(request?.data?.contract?.indResponsabilite)}
                        change={formatNumber(request?.data?.indResponsabilite)}
                      />

                      <Row
                        label={"Indemnité de représentation"}
                        current={formatNumber(request?.data?.contract?.indRepresentation)}
                        change={formatNumber(request?.data?.indRepresentation)}
                      />

                      <Row
                        label={"Prime d'exploitation"}
                        current={formatNumber(request?.data?.contract?.primeExploitation)}
                        change={formatNumber(request?.data?.primeExploitation)}
                      />

                      <Row
                        label={"Sur salaire"}
                        current={formatNumber(request?.data?.contract?.sursalaire)}
                        change={formatNumber(request?.data?.sursalaire)}
                      />

                      <Row
                        label={"Prime de salissure"}
                        current={formatNumber(request?.data?.contract?.primeSalissure)}
                        change={formatNumber(request?.data?.primeSalissure)}
                      />

                      <Row
                        label={"Indemnité de caisse"}
                        current={formatNumber(request?.data?.contract?.indCaisse)}
                        change={formatNumber(request?.data?.indCaisse)}
                      />

                      <Row
                        label={"Bonus"}
                        current={formatNumber(request?.data?.contract?.bonus)}
                        change={formatNumber(request?.data?.bonus)}
                      />

                      <Row
                        label={"Prime panier"}
                        current={formatNumber(request?.data?.contract?.primePanier)}
                        change={formatNumber(request?.data?.primePanier)}
                      />

                      <Row
                        label={"Billet d'avion"}
                        current={formatNumber(request?.data?.contract?.billetAvion)}
                        change={formatNumber(request?.data?.billetAvion)}
                      />

                      <Row
                        label={"Autres primes"}
                        current={formatNumber(request?.data?.contract?.autresPrimes)}
                        change={formatNumber(request?.data?.autresPrimes)}
                      />

                      <Row
                        label={"Sur salaire"}
                        current={formatNumber(request?.data?.contract?.sursalary)}
                        change={formatNumber(request?.data?.sursalary)}
                      />

                      <Row
                        label={"Numéro du contrat"}
                        current={request?.data?.contract?.contratNumber}
                        change={request?.data?.contratNumber}
                      />

                      <Row
                        label={"Durée du contrat"}
                        current={request?.data?.contract?.contratDuration}
                        change={request?.data?.contratDuration}
                      />

                      <Row
                        label={"Date de début du contrat"}
                        current={request?.data?.contract?.contractStart}
                        change={request?.data?.contractStart}
                      />

                      <Row
                        label={"Numéro du permis"}
                        current={request?.data?.contract?.permitNumber}
                        change={request?.data?.permitNumber}
                      />

                      <Row
                        label={"Date d'optention du permis"}
                        current={request?.data?.contract?.permitDate}
                        change={request?.data?.permitDate}
                      />


                    </div>
                  </Section>
                  }
                </>}


              <Divider />


              {files?.map((f, index) => {

                return <div className={request?.data?.status =='PENDING' ? `border border-${f?.openned ? 'green-500' : 'red-500'}` : ``}
                  onClick={() => {
                    setFiles(files.map((file) => {
                      if (file.name == f.name) {
                        return { name: file.name, openned: true }
                      }
                      else {
                        return file
                      }
                    }))
                  }}>
                  <MuiCollapse
                    key={index}
                    title={"Fichiers--" + (index + 1)}
                    subTitle={request?.data?.status =='PENDING' ? f.openned ?
                      <span className="flex items-center gap-4"><BsCheckCircleFill color="green" /> <span >Ouvert</span> </span> :
                      <span className="flex items-center gap-4"><BsExclamationCircleFill color="orange" /><span className="text text-orange-400">Veillez ouvrir ce fichier</span></span>
                    :
                    <></>
                    }
                    index={index + 1}
                    isExpandedP={false}>

                    <PDFViewer fileName={f} />
                  </MuiCollapse></div>
              })}



            </div>

          </div>
        </DialogContent>
        <DialogActions className=" flex flex-1  gap-2 p-4">

          <div className="flex justify-start flex-1">

            <MButton variant='danger'
              onClick={(e) => {
                e?.preventDefault(); e?.stopPropagation();
                handleCloseInside()

              }}
            // disabled={selectedEnterprise ? false : true}
            // isLoading={loadingDisableEnter}
            >
              Fermer
            </MButton>
          </div>


        </DialogActions>
      </Dialog >


    </>
  );
};

export default RequestDetails;

