
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "components/fields/InputField";
import Error from "components/message/Error";
import useDataFetching from "hooks/useDataFetching";
import usePostAxiosData from "hooks/usePostAxiosData";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { config } from "variables/config";
import { ROLES } from "Constaints";
import PDFViewer from "components/PDFViewer";
import AddEnterprise, { Transition } from "../tables/AddEnterprise";
import { AppBar, capitalize, Chip, CircularProgress, Dialog, DialogActions, DialogContent, Divider, IconButton, Skeleton, Toolbar, Typography } from "@mui/material";
import MButton from "components/Mbutton";
import MuiAUtocomplete from "components/MuiAutocomplete";
import { ENTERPRISE_HEADER } from "Constaints";
import { FromArchiveIcon } from "components/TableIcon";
import { FromTheFieldIcon } from "components/TableIcon";
import { CHECK_PERMISSIONS } from "Constaints";
import { BsActivity, BsCheck2All, BsGeoAlt, BsLink, BsList, BsPeople, BsPersonCheckFill, BsSearch } from "react-icons/bs";
import { createMapLink } from "utils";
import MuiTable from "components/MuiTable";
import { CloseModalButton } from "components/Utils";
import MuiCollapse from "components/MuiCollapse";
import { Badge } from "@chakra-ui/react";
import PaginateBox from "components/PaginateBox";
import { lime } from "@mui/material/colors";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { COLORS } from "constant/COLORS";

const EnterpriseAccountDetails = (props) => {
  // const { isOpen, onClose, setIsOpen, selectedUserP, fetchData } = props;
  const location = useLocation();
  const navigation = useNavigate()
  const { id } = useParams();
  const { selectedUser } = location.state || {}; // Destructure the data from state
  // console.log("🚀 ~ EnterpriseAccountDetails ~ selectedUser:", selectedUser)
  const [toDisplayEnterprise, settoDisplayEnterprise] = useState(null);
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);

  /** get user by id */
  const [loadingU, errorU, user, fetchDataU] = useDataFetching(`${config.app.api_url}/users/${id || selectedUser?.id}`);
  const [loadingCheckLing, errorLink, alreadyLinked, CheckLink] = useDataFetching(selectedEnterprise?.nui ? `${config.app.api_url}/users/check-existing-linked-enterprise?niu=${selectedEnterprise?.nui}` : null);
  const [loadingEN, errorEnNiu, enterPriseByNui, getEnterpriseByniu] = useDataFetching(!user?.data?.enable ? `${config.app.api_url}/enterprises/search-by-nui/${selectedUser?.myEnterpriseNiu}` : null);

  const [loadingEnter, errorENter, enterprises, getSimilarEnterprises] = useDataFetching(!user?.data?.enable ? `${config.app.api_url}/enterprises/search-like/${selectedUser?.myEnterpriseName}` : null);

  const [loadingEnable, postEnable, errorPost, successMessageEnable, resultEnable] = usePostAxiosData(`users/${id || selectedUser?.id}/enable-enterprise-account`, "POST");
  const [loadingDisable, postDisable, errorPostDisable, successMessageDisable, resultDisable] = usePostAxiosData(`users/${id || selectedUser?.id}/disable-account`, "POST");
  const [loadingDisableEnter, postDisableEnt, errorPostDisableEnter, successMessageDisableEnter, resultDisableEnter] = usePostAxiosData(`users/${id || selectedUser?.id}/disable-enterprise-account`, "POST");

  // useEffect(() => {
  //   getEnterpriseByniu()
  //   getSimilarEnterprises()
  // }, [user]);
  // console.log("🚀 ~ EnterpriseAccountDetails ~ enterprises:", enterprises)
  /** Form validation config */
  const [params, setParams] = useState({
    page: 0,
    limit: 25
  });
  const schema = yup.object().shape({
    firstname: yup.string().nullable(),
    lastname: yup.string().nullable(),
    phone: yup.string().nullable(),
    email: yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    // handleUpdate(data);
    // reset();
  };

  //   console.log('user', user?.data?.lastName);

  const [roles, setRoles] = useState([]);
  console.log("🚀 ~ EnterpriseAccountDetails ~ roles:", roles)

  useEffect(() => {
    setValue("lastname", user?.data?.lastName);
    setValue("firstname", user?.data?.firstName);
    setValue("phone", user?.data?.phone);
    setValue("email", user?.data?.mail);
    setValue("myEnterpriseName", user?.data?.myEnterpriseName);
    setValue("myEnterpriseNiu", user?.data?.myEnterpriseNiu);
    setRoles(user?.data?.roles?.map((r) => r?.name))
  }, [user]);

  //   console.log("lastname", lastname);

  /** post create user */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`users/${selectedUser?.id}`, "POST");
  const navigate = useNavigate()
  const handleUpdate = async (dataYub) => {
    // console.log('dataYub', dataYub);
    const data = {
      firstName: dataYub?.firstname,
      lastName: dataYub?.lastname,
      phone: dataYub?.phone,
      email: dataYub?.email,
      roles: roles,
    };

    await postAxiosData(data);
  };

  const handleChangeValue = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    setRoles(value);
  };

  /** gestion des erreurs et succès */
  useEffect(() => { }, [errorMassage]);
  useEffect(() => {
    if (successMessage?.status === 200 || successMessage?.status === 201) {
      toast.success(`${successMessage?.message} `, {
        duration: 4000,
        // icon: "👏",
        className: "bg-success text-white",
      });

      // setIsOpen(false);
      fetchDataU();

      // handleSignin(result);
    }
  }, [successMessage]);
  useEffect(() => {
    if (successMessageDisable?.status === 200 || successMessageDisable?.status === 201) {
      toast.success(`${successMessageDisable?.message} `, {
        duration: 4000,
        // icon: "👏",
        className: "bg-success text-white",
      });

      // setIsOpen(false);
      fetchDataU();

      // handleSignin(result);
    }
  }, [successMessageDisable]);
  useEffect(() => {
    if (successMessageEnable?.status === 200 || successMessageEnable?.status === 201) {
      toast.success(`${successMessageEnable?.message} `, {
        duration: 4000,
        // icon: "👏",
        className: "bg-success text-white",
      });

      // setIsOpen(false);
      fetchDataU();

      // handleSignin(result);
    }
  }, [successMessageEnable]);

  useEffect(() => {
    if (successMessageDisableEnter?.status === 200 || successMessageDisableEnter?.status === 201) {
      toast.success(`${successMessageDisableEnter?.message} `, {
        duration: 4000,
        // icon: "👏",
        className: "bg-success text-white",
      });

      // setIsOpen(false);
      fetchDataU();

      // handleSignin(result);
    }
  }, [successMessageDisableEnter]);



  const [open, setOpen] = useState(false);
  const handleClickEnter = (item) => {
    // navigate(`/admin/enterprises/${item?.id}`)
    settoDisplayEnterprise(item)
    setOpen(true)
  }

  const handleSelectEnterprise = (item) => {
    item && setSelectedEnterprise(item)
  }



  const handleUnSelectEnterprise = (item) => {
    item && setSelectedEnterprise(enterPriseByNui?.data)
  }



  useEffect(() => {
    if (user?.data?.enable) {
      setSelectedEnterprise(user?.data?.myEnterprise)
    } else {
      enterPriseByNui?.data && setSelectedEnterprise(enterPriseByNui?.data)

    }
  }, [enterPriseByNui, user]);



  function createData(N, Raison, Secteur, Arrondissement, Expatriés, Coordonnées, Options) {
    // const density = population / size;
    return { N, Raison, Secteur, Arrondissement, Expatriés, Coordonnées, Options };
  }
  const rows = enterprises?.data?.content?.map((item, index) => {
    return {
      // color: !checkIsValidPermitDate(item) ? 'red' : '',
      handleClick: () => handleClickEnter(item),

      datas: createData((params?.page * params?.limit) + (index + 1), <>{item?.fromArchive ?
        <FromArchiveIcon />
        :

        <FromTheFieldIcon />}

        <span className="fs-c">{(item?.socialRaison)}</span>
      </>
        ,
        item?.activity,
        item?.arrondissement,
        (item?.employeesNumber != null ? item?.employeesNumber : '--'),
        user?.data?.roles.length > 0 &&
        user?.data?.roles?.map((role) => CHECK_PERMISSIONS(
          role?.permissions,
          "Voir une entreprise sur google map"
        ) ? (
          <>
            {(item?.latitude && item?.longitude) ?
              <>
                <Chip
                  icon={<BsGeoAlt />}
                  color="primary"
                  className="capitalize-none"

                  variant="outlined"
                  label={capitalize("Voir sur la map")}
                  onClick={(e) => {
                    e.preventDefault();
                    e?.stopPropagation();

                    if (item?.latitude && item?.longitude) {
                      window.open(createMapLink(`${item?.latitude},${item?.longitude}`), '_blank', 'noopener,noreferrer');
                    } else { alert("Coordonées incomplètes"); }
                  }} />

              </>
              :
              (item?.searchLatitude && item?.searchLongitude) ?
                <>
                  <Chip
                    icon={<BsGeoAlt />}
                    color="warning"
                    className="capitalize-none"

                    variant="outlined"
                    label={capitalize("Vérifier sur la map")}
                    onClick={(e) => {
                      e.preventDefault();
                      e?.stopPropagation();

                      if (item?.searchLatitude && item?.searchLongitude) {
                        window.open(createMapLink(`${item?.searchLatitude},${item?.searchLongitude}`), '_blank', 'noopener,noreferrer');
                      } else { alert("Coordonées incomplètes"); }
                    }} />

                </> :
                <Chip
                  icon={<BsSearch />}
                  // color="warning"
                  // variant="outlined"
                  className="capitalize-none"
                  label={capitalize("Recherher sur la map")}
                  onClick={(e) => {
                    e.preventDefault();
                    e?.stopPropagation();
                    window.open(createMapLink(`${item?.socialRaison} ${item?.sigle} ${item?.street} CAMEROON`), '_blank', 'noopener,noreferrer');
                  }} />

            }
          </>
        ) : (
          <></>

        ))
        // ,
        // <span>--</span>
        ,
        selectedEnterprise?.nui == item?.nui ? <MButton
          variant={"primary"}
          onClick={(e) => {
            handleUnSelectEnterprise(item)
            e.preventDefault();
            e?.stopPropagation()
          }}>
          {/* <BsList /> */}
          Vous l'avez selectionne
        </MButton> :
          <MButton
            variant={selectedEnterprise?.nui == item?.nui ? "primary" : "white"}
            onClick={(e) => {
              handleSelectEnterprise(item)
              e.preventDefault(); e?.stopPropagation()
            }}>
            {/* <BsList /> */}
            Selectionner
          </MButton>
      )

    }
  }
  )


  const handleClose = () => {
    navigation(-1)
  }

  const handleEnable = () => {
    let confirm = true
    if (!selectedEnterprise) {
      confirm = window.confirm("Pas d'entreprise selectionner! poursuivre cette action engendra la creation d'une nouvelle entreprise")
    }
    confirm && postEnable({ selectedEnterpriseId: selectedEnterprise?.id })
  }

  const handleDisable = () => {
    let confirm = true
    confirm = window.confirm("Etes vous sur de bien vouloir desactiver ce compte ? ")
    confirm && postDisable({})
  }

  const handleDisableEnterAccount = () => {
    let confirm = true
    confirm = window.confirm("Etes vous sur de bien vouloir rompre le lien entre ce compte et son entreprise ? ")
    confirm && postDisableEnt({})
  }
  useEffect(() => {
    if (successMessageEnable?.status === 200 || successMessageEnable?.status === 201) {
      fetchDataU()
      toast.success(
        `${successMessageEnable?.message != null
          ? successMessageEnable?.message
          : "Etat de l'entreprise mis a jour avec success."
        } `,
        {
          duration: 4000,
          className: "bg-success text-white",
        }
      );
    }
  }, [successMessageEnable]);

  const DisplayStatus = () => {
    if (loadingU) {
      return <CircularProgress className="mx-auto" color="success" />
    }
    if (user?.data?.enable) {
      return <span className="bg-green-50 text-green-500 rounded-[60px] p-2 flex gap-2 content-center items-center"><BsPersonCheckFill size={25} color="orange" /> Compte Actif</span>

    } else {
      return <span className="bg-red-100 text-red-500 rounded-[60px] p-2">Compte Inactif</span>

    }
  }
  const DisplayLinkStatus = () => {
    if (loadingCheckLing) {
      return <CircularProgress className="mx-auto" color="success" />
    }
    if (alreadyLinked?.data === false) {
      return <span className="bg-green-50 text-green-500 rounded-[60px] p-2">Liason possible</span>

    } else {
      if (selectedEnterprise?.nui == user?.data?.myEnterprise?.nui) {
        return <span className="bg-green-100 text-green-500 rounded-[60px] p-2 flex gap-2 content-center items-center"><BsLink size={30} color="orange" /> <Typography>Entreprise deja lie a moi</Typography></span>

      }
      return <span className="bg-red-100 text-red-500 rounded-[60px] p-2">Entreprise deja lie a un autre compte</span>

    }
  }

  useEffect(() => {
    if (selectedEnterprise) {
      CheckLink()
    }
  }, [selectedEnterprise]);
  return (
    user?.data && <>
      {open && toDisplayEnterprise && <AddEnterprise open={open}
        selectedEnterpriseP={toDisplayEnterprise}
        handleClose={() => { setOpen(false); settoDisplayEnterprise(null) }}
        get={() => null} />}
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative' }}>
          <Toolbar className="flex justify-between bg-gray-50">
            <Typography color={'black'}>
              Informations du compte enterprise
            </Typography>
            <div className="flex gap-2">

              <DisplayStatus />
            </div>
            <div className="flex gap-4">
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => navigation(`requests`)}

                aria-label="close"

              >

                <BsList className="text-blue-500" />

              </IconButton>
              <CloseModalButton iconColor={"red"} handleClose={handleClose} />
            </div>

          </Toolbar>
        </AppBar>

        <DialogContent dividers>

          <div className="flex flex-col gap-4">
            {errorPost?.message && (
              <Error
                message={
                  errorPost?.message !== "" && errorPost?.message
                }
              />
            )}
            <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
              {loadingU ? <Skeleton height={250} width={'100%'} /> :
                <>
                  {errorMassage?.message && (
                    <Error
                      message={
                        errorMassage?.message !== "" && errorMassage?.message
                      }
                    />
                  )}
                  <div className="mt-4 grid gap-2 md:grid-cols-2">
                    <div>
                      {/* <span className="ms-1">Change user role(s)</span>
                  <Select
                    className="mt-2"
                    height={20}
                    value={roles}
                    onChange={(e) => handleChangeValue(e)}
                  >
                    {ROLES?.map((role) => {
                      return <option value={role}>{role}</option>;
                    })}
                  </Select> */}
                      {roles && <MuiAUtocomplete
                        // disabled={!canEdit || sector?.length > 4}
                        multiple={true}
                        value={roles}
                        label={'Roles'}
                        options={ROLES?.map((r) => { return { value: r, label: r } })}
                        format={{ value: 'value', label: 'label' }}
                        // value={params?.regionId}
                        // defaultFormat={true}
                        onChange={(event, items) => setRoles(items)}
                        // onChange={(e, item) => console.log(item)}
                        wrapperClasses={"flex-1 filter-item anime slideRight"}
                      // inputValue={activityName}
                      // onInputChange={(event, newInputValue) => {
                      //   setActivityName(newInputValue);
                      // }}
                      // handleNoOption={
                      //   <MButton onClick={handleAddSector} >
                      //     Ajouter ce sectur d'activite
                      //   </MButton>
                      // }
                      />}

                    </div>
                    <div>
                      <InputField
                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Prénom(s)"
                        required={true}
                        placeholder="Prénom"
                        id="firstname"
                        name="firstname"
                        type="text"
                      />
                    </div>
                    <div>
                      <InputField
                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Nom(s)"
                        required={true}
                        placeholder="Nom"
                        id="lastname"
                        name="lastname"
                        type="text"
                      />
                    </div>
                    <div>
                      <InputField
                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Numéro de téléphone"
                        required={true}
                        placeholder="690128925"
                        id="phone"
                        name="phone"
                        type="text"
                      />
                    </div>
                    <div>
                      <InputField
                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Adresse Mail"
                        required={true}
                        placeholder="adresse@gmail.com"
                        id="email"
                        name="email"
                        type="email"
                      />
                    </div>
                    <div>
                      <InputField
                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Raison social"
                        required={true}
                        placeholder="Nom de l'entreprise"
                        id="myEnterpriseName"
                        name="myEnterpriseName"
                        type="text"
                      />
                    </div>
                    <div>
                      <InputField
                        register={register}
                        variant="auth"
                        extra="mb-3"
                        label="Entrer la raison Sociale"
                        required={true}
                        placeholder="Entre le niu"
                        id="myEnterpriseNiu"
                        name="myEnterpriseNiu"
                        type="text"
                      />
                    </div>

                  </div>

                </>

              }
              <Divider />

              <div className="bg-gray-100 rounded p-5  flex flex-col gap-5">
                <MuiCollapse index={1} isExpandedP={true}
                  title={user?.data?.enable ? "Lie a l'entreprise suivante" : selectedEnterprise?.nui == selectedUser?.myEnterpriseNiu ? "Informations sur l'entreprise selectionne par  NIU" : "Informations sur l'entreprise selectionne depuis la liste de suggestion"}
                  subTitle={user?.data?.enable || selectedEnterprise?.nui == selectedUser?.myEnterpriseNiu ? <> <DisplayLinkStatus /></> :
                    <div className="flex items-center flex-1 ms-2 gap-5 justify-between">
                      <DisplayLinkStatus />

                      <MButton
                        variant={"secondary"}

                        onClick={(e) => {
                          e?.preventDefault()
                          e?.stopPropagation()
                          setSelectedEnterprise(enterPriseByNui?.data)
                        }}>
                        {/* <BsList /> */}
                        Reinitialiser
                      </MButton>
                    </div>

                  }
                // bgColor={COLORS}
                >
                  {loadingEN ? <Skeleton height={250} width={'100%'} /> : selectedEnterprise ? <div className="grid grid-cols-2 gap-4">

                    <InputField
                      extra="mb-3"
                      label="raison sociale"
                      type="text"
                      disable={true}
                      value={selectedEnterprise?.socialRaison}
                    />
                    <InputField
                      extra="mb-3"
                      label="Sigle"
                      type="text"
                      disable={true}
                      value={selectedEnterprise?.sigle}
                    />
                    <InputField
                      extra="mb-3"
                      label="niu"
                      type="text"
                      disable={true}
                      value={selectedEnterprise?.nui}
                    />

                    <InputField
                      extra="mb-3"
                      label="region"
                      type="text"
                      disable={true}
                      value={selectedEnterprise?.region?.name}
                    />


                    <InputField
                      extra="mb-3"
                      label="Department"
                      type="text"
                      disable={true}
                      value={selectedEnterprise?.department?.name}

                    />


                    <InputField
                      extra="mb-3"
                      label="Arrondissement"
                      type="text"
                      disable={true}
                      value={selectedEnterprise?.arrondissement?.name}
                    />




                    <InputField
                      extra="mb-3"
                      label="Activites"
                      type="text"
                      disable={true}
                      multiline
                      value={selectedEnterprise?.activitySectors?.map((a) => a?.name)?.join(',')}
                    />
                    <InputField
                      extra="mb-3"
                      label="Createur"
                      type="text"
                      disable={true}
                      value={selectedEnterprise?.creator?.firstName}
                    />

                  </div> :
                    <Typography variant="h6" color={'red'}>Pas d'entreprise selectionee</Typography>
                  }
                  <div className="flex justify-end me-3">
                    <Link
                      className="text-blue-500  bg-blue-100 p-2 rounded-[60px]"
                      onClick={(e) => {
                        e?.preventDefault()
                        e?.stopPropagation()
                        handleClickEnter(selectedEnterprise)
                      }}>Voir plus de details...</Link>
                  </div>
                </MuiCollapse>


                <Divider />
                {!user?.data?.enable &&
                  <MuiCollapse index={2} isExpandedP={!enterPriseByNui?.data && rows?.length > 0}
                    subTitle={<Badge
                      fontSize={13}
                      rounded={5}
                      padding={1}
                      paddingInline={2}
                      colorScheme="green"
                      className="mx-1"
                    // style={{ wi: -15 }}
                    >
                      {enterprises?.data?.totalElements || '--'}
                    </Badge>} title={"Liste des entreprise avec la raison social presque similaires"}>
                    {loadingEnter ? <Skeleton height={250} width={'100%'} /> : <MuiTable hidePagiation={true} columns={ENTERPRISE_HEADER}
                      rows={rows} textEmpty={"Pas de permis de travail"} />}

                    <PaginateBox

                      total={enterprises?.data?.totalElements}
                      rowsPerPage={params?.limit}
                      handleChangeRowsPerPage={(event) => {
                        // alert("change")
                        // console.log("🚀 ~ EmployeList ~ event:", event.target.value)
                        setParams({
                          ...params
                          , limit: parseInt(event.target.value),
                          page: 0,
                        });
                        // (0);
                      }}
                      page={params.page}
                      count={enterprises?.data?.totalPages - 1}
                      handleChange={(event, value) => {
                        setParams({ ...params, page: value });
                      }} />

                  </MuiCollapse>
                }
              </div>

              <Divider />
              <div>
                <Typography variant="h5" align="center">
                  Attestation d'immatriculation
                </Typography>
                <PDFViewer fileName={user?.data?.myNiuPathFile} />
              </div>

            </form>

          </div>
        </DialogContent>
        <DialogActions className="flex justify-between  gap-2 p-4">
          {/* <div className="flex justify-end flex-1 gap-4"> */}
          {/* <MButton variant='danger' onClick={() => setIsOpen(false)}>Fermer</MButton> */}

          {user?.data?.enable ?
            <MButton variant='danger'
              onClick={(e) => { e?.preventDefault(); e?.stopPropagation(); handleDisable() }}
              isLoading={loadingDisable}

            >Desactiver</MButton>
            :
            <>
              <MButton variant='danger'
                onClick={(e) => { e?.preventDefault(); e?.stopPropagation(); handleDisableEnterAccount() }}
                // disabled={selectedEnterprise ? false : true}
                isLoading={loadingDisableEnter}
              >
                Separer
              </MButton>
              <MButton variant='primary'
                onClick={(e) => { e?.preventDefault(); e?.stopPropagation(); handleEnable() }}
                // disabled={selectedEnterprise ? false : true}
                isLoading={loadingEnable}
              >
                Activer & lier
              </MButton>
            </>
          }
          {/* </div> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EnterpriseAccountDetails;
