import {

  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import AddUser from "./AddUser";
import UpdateUserInformations from "./UpdateUserInformations";
import { useState } from "react";
import PreloaderSpinner from "components/PreloaderSpinner";
import { ROLES } from "Constaints";
import { useAuthUser } from "react-auth-kit";
import { CHECK_PERMISSIONS } from "Constaints";
import { capitalize, Chip } from "@mui/material";
import MButton from "components/Mbutton";
import MuiTable from "components/MuiTable";
import { ACCOUNT_HEADER } from "Constaints";
import usePostAxiosData from "hooks/usePostAxiosData";
import EnterpriseAccountDetails from "./EnterpriseAccountDetails";

const EnterpriseAcccount = () => {
  const navigation = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCloseModal = () => {
    setselectedUser(null)
    onClose()
  }
  const [isOpenUM, setIsOpenUM] = useState(false);
  const [closeUM, setCloseUM] = useState(false);


  const auth = useAuthUser();
  const [params, setParams] = useState({limit : 25, page : 0});

  const [loadingU, errorU, users, fetchDataU] = useDataFetching(
    `${config.app.api_url}/roles/enterprises`
  );

  const [selectedUser, setselectedUser] = useState(null);


  function createData(N, FirstName, LastName, Email, Phone, Enable, Options) {
    // const density = population / size;
    return {
      N, FirstName, LastName, Email, Phone, Enable, Options

    };
  }
  const handleClick = (user) => {
    navigation(`/admin/account/enterprises/${user.id}`, { state: { selectedUser: user } })
    // setIsOpenUM(true);
    // setselectedUser(user);
  }
  const rows = users?.data?.map((item, index) => {
    // const l = getLastContract(item?.contracts)

    return {
      // color: !checkIsValidPermitDate(item) ? 'red' : '',
      handleClick: (e) => handleClick(item),

      datas: createData((index + 1),

        item.firstName,
        item?.lastName
        ,
        item?.mail,
        // ENTRY_TYPE[item?.entryType] || '--',

        item?.phone,

        item?.enable ?
        <span className="bg-green-50 text-green-500 rounded-[60px] p-2 flex gap-2 content-center items-center"> Compte Actif</span>
        :
        <span className="bg-red-100 text-red-500 rounded-[60px] p-2">Compte Inactif</span>
           

          ,
        <div
          onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
          className="flex item-center justify-center gap-2">
          {/* {item?.enable === false &&

            <MButton
              variant={"danger"}

              style={{ borderRadius: 20 }} onClick={() => handleUnlinkAccount(item)}>
              Unlink enterprise
            </MButton>
          } */}
          <MButton
            variant={"white"}

            style={{ borderRadius: 20 }} onClick={() => handleClick(item)}>
            Consulter
          </MButton>,
        </div>

      ),

    }
  }
  )

  return (
    <div>
      {/* {loadingU && <PreloaderSpinner />} */}
      <div className="mt-2 grid h-full grid-cols-1 gap-5">
        <div className="mt-5 flex items-center justify-between">
          <div>

          </div>
          <div>

          </div>
        </div>
        {/* <div>
          <label
            className={`ml-3 text-3xl font-bold text-navy-700 dark:text-white`}
          >
            Liste des comptes
          </label>
        </div> */}

        <MuiTable
          hidePagiation={true}
          loading={loadingU}
          limit={params?.limit}
          columns={ACCOUNT_HEADER}
          rows={rows} textEmpty={"Pas de permis de travail"} />
      </div>

      {/* Modal création d'un user */}
      {selectedUser && <AddUser isOpen={isOpen} onClose={handleCloseModal} />}

      {/* Modification des informations du user */}
      {selectedUser && <EnterpriseAccountDetails
        fetchData={fetchDataU}
        isOpen={isOpenUM}
        setIsOpen={setIsOpenUM}
        onClose={handleCloseModal}
        selectedUser={selectedUser}
      />}
    </div>
  );
};

export default EnterpriseAcccount;
